import React, { ReactElement } from 'react';
import './Home.css';
import { IHomeController } from './HomeController';
import HeroImg from '../../assets/images/hero-img.jpg';
import EnterDataImg1 from '../../assets/images/enter_data.jpg';
import EnterDataImg2 from '../../assets/images/enter_data_2.jpg';
import StatisticsImg from '../../assets/images/statistics.jpg';
import StatisticsImg2 from '../../assets/images/see_statistics.jpg';

import Hero from '../../components/hero/Hero';
import Button from '../../components/button/Button';
import { FaGooglePlay, FaAppStoreIos, FaFacebook, FaMailBulk } from 'react-icons/fa';
import Quote from '../../components/quote/Quote';
import ImageSection from '../../components/photo-section/ImageSection';

interface HomeState {

}

interface HomeProps {
  controller: IHomeController;
}

export default class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <Hero
          img={HeroImg}
          text={'Intimassy'}
          text2={'Intimacy Tracker App for Android and iOS'}
          subText={'Track, Organize and Improve Your Sex Life'}
          buttons={
            this.getHeroButtons()
          }
        />
        <div className='content-container'>
          <p className='p content-style-center-bold'>Intimassy the Love Diary mobile app lets you track sexual activities, sex life, sexual health, sexual intimacy and gives you sexual statistics about your private life for better sexual wellness. </p>
          <hr className='content-divider' />
          <Quote
            text='Catherine S. - May 4, 2020'
            subText="&quot;Really easy to use. Took a while to fill it out the first time having to plug everything in, but it's super fast now to keep track. I would definitely recommend using this app.&quot;"
          ></Quote>
          <Quote
            text='Marie W. - February 11, 2020'
            subText="&quot;I love it. I'm a female but I like 2 keep track of what I'm doing & how much enjoyment I'm truly getting over the course of a relationship. Also, love that I can put personal details/notes.&quot;"
          ></Quote>
          <Quote
            text='Horry H. - Dec 12, 2021'
            subText="&quot;As a very active couple we have the app for over 2 years now and can only say positive things about it. It is extremely useful to keep track of your activities. We will definitely recommend the app to anyone who is interested. And to the developers, you've done a great job!&quot;"
          ></Quote>

          <hr className='content-divider' />

          <ImageSection
            img={EnterDataImg1}
            text={'Enter Your Data'}
            subText={'Enter the details of your sexual activity!'}
            textOnLeft={true}
          />

          <hr className='content-divider' />

          <ImageSection
            img={EnterDataImg2}
            text={'Customize Your Diary'}
            subText={'Create your partners, positions, places profiles, keep your relationship history!'}
            textOnLeft={false}
          />

          <hr className='content-divider' />

          <ImageSection
            img={StatisticsImg}
            text={'See Statistics'}
            subText={'Get rich feedback with visualised statistics, compare your own statistics with Global Statistics and improve your sex life!'}
            textOnLeft={true}
          />

          <hr className='content-divider' />

          <ImageSection
            img={StatisticsImg2}
            text={'Visualized Feedback'}
            subText={'Improve your sex life by visualizing the statistics to and receiving detailed visual feedback.'}
            textOnLeft={false}
          />

          <hr className='content-divider' />

          <h1 className='header-style'>Features</h1>
          <p className='p content-style-center-bold'>You can see sexual statistics and get sexual feedback with more than 10 different metrics to improve your sex life and have better male and female orgasms! </p>
          <div className='list-container'>
            <ul className='list-style-center'>
              <li>Specify your sexual partner for each sexual activity</li>
              <li>You can add photos and private images to remember special moments</li>
              <li>Sexual intercourse type</li>
              <li>Sex place</li>
              <li>Sex position</li>
              <li>Sex duration</li>
              <li>Sexual protection</li>
              <li>Orgasm count</li>
              <li>Sexual partner's orgasm count</li>
              <li>Extra notes</li>
              <li>PIN protection</li>
              <li>Partner based and overall statistics including Calories burnt</li>
            </ul>
          </div>
        </div>

        <br />

        <div className={'background row'}>
          <div className={'row-item'}>
            < Button
              text={'Facebook Page'}
              icon={< FaFacebook />}
              onClick={
                () => {
                  window.open('https://www.facebook.com/sexlifetracker');
                }
              }
            />
          </div>
          <div className={'row-item'}>
            < Button
              text={'Contact Us'}
              icon={< FaMailBulk />}
              onClick={
                () => {
                  window.open('mailto:support@centertable.club');
                }
              }
            />
          </div>
        </div>
        <small className={'copyright-text'}>
          Copyright © Center Table Inc. All Rights Reserved
        </small>
      </div>
    );
  }

  getHeroButtons(): Array<ReactElement> {
    var buttons: Array<ReactElement> = [];

    buttons.push(
      <Button
        text={'Google Play Store'}
        icon={<FaGooglePlay />}
        onClick={
          () => {
            window.open('https://play.google.com/store/apps/details?id=centertable.advancedscalendar');
          }
        }
      />
    )

    buttons.push(
      <Button
        text={'App Store'}
        icon={<FaAppStoreIos />}
        onClick={
          () => {
            window.open('https://apps.apple.com/us/app/intimassy-sex-tracker-logger/id1508195872');
          }
        }
      />
    )

    return buttons;
  }
}
