import React from 'react';
import './ImageSection.css';

interface ImageSectionState {
  textFirst: boolean;
  direction: string;
}

interface ImageSectionProps {
  text: string;
  subText: string;
  img: string;
  textOnLeft?: boolean;
}

export default class ImageSection extends React.Component<ImageSectionProps, ImageSectionState> {
  constructor(props: ImageSectionProps) {
    super(props);
    this.state = {
      textFirst: this.props.textOnLeft ?? true,
      direction: 'row',
    };

    window.addEventListener("resize", () => this.setLayout(window.innerWidth));
  }

  componentDidMount() {
    this.setLayout(window.innerWidth);
  }

  setLayout(innerWidth: number) {
    // The current width of the viewport
    const width = innerWidth;
    // The width below which the mobile view should be rendered
    const breakpoint = 800;
    const textFirstVal = this.props.textOnLeft || width < breakpoint;
    const imageClassVal = (width < breakpoint) ? 'column' : 'row';
    this.setState({
      textFirst: textFirstVal,
      direction: imageClassVal,
    });
  }

  render() {
    return (
      <div className={'image-section-container' + '-' + this.state.direction}>

        {!this.state.textFirst &&
          <img className={'image-section-image' + '-' + this.state.direction} src={this.props.img} alt={this.props.text}/>
        }

        <div className='image-section-text-container'>
          <h1 className='image-section-text'>{this.props.text}</h1>
          <p className='image-section-sub-text'>{this.props.subText}</p>
        </div>

        {this.state.textFirst &&
          <img className={'image-section-image' + '-' + this.state.direction} src={this.props.img} alt={this.props.text}/>
        }
      </div>
    );
  }
}
