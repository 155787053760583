import React from 'react';
import './App.css';
import Home from './modules/home/Home';
import { HomeController } from './modules/home/HomeController';
import ReactGA from 'react-ga4';


export default class App extends React.Component {

  constructor(props: any) {
    super(props);

    const TRACKING_ID = "G-DGC07PD82G"; 
    ReactGA.initialize(TRACKING_ID);
  }

  render() {
    return (
      <Home
        controller={new HomeController()}
      />
    );
  }
}
