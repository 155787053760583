import React, { ReactElement } from 'react';
import Button from '../button/Button';
import './Hero.css';

interface HeroState {

}

interface HeroProps {
  text?: string;
  text2?: string;
  subText?: string;
  img: string;
  buttons?: Array<ReactElement>;
}

export default class Hero extends React.Component<HeroProps, HeroState> {
  constructor(props: HeroProps) {
    super(props);
  }

  render() {
    return (
      <div className='hero-container'>

        <img className='hero-img' src={this.props.img} alt="Intimassy App Hero" />

        <div className='text-container'>
          <h1 className='h1 hero-text'>{this.props.text}</h1>
          <h1 className='h1 hero-text2'>{this.props.text2}</h1>
          <h2 className='h2 hero-subtext'>{this.props.subText}</h2>
          <hr className='hero-divider' />

          <div className='hero-buttons-container'>
            {this.props.buttons?.map(button => (
              <div style={{
                margin: '5px',
                padding: '5px'
              }}>
                {button}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
