import React from 'react';
import './Button.css';
import { Button as MaterialButton } from '@mui/material';
import ReactGA from 'react-ga4';

interface ButtonState {

}

interface ButtonProps {
  text: string;
  icon: any;
  onClick: Function;
}

export default class Button extends React.Component<ButtonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props);
  }

  render() {
    return (
      <MaterialButton
        style={{
          borderRadius: 5,
          backgroundColor: "#ffffff",
          fontSize: "0.8em",
          color: 'black',
          minWidth: "20vw",
        }}
        onClick={
          () => {
            ReactGA.event({
              category: 'Button',
              action: this.props.text
            });
            
            this.props.onClick();
          }
        }
        variant="contained" startIcon={this.props.icon} >
        {this.props.text}
      </MaterialButton>
    );
  }
}
