import React from 'react';
import './Quote.css';

interface QuoteState {

}

interface QuoteProps {
  text: string;
  subText: string;
}

export default class Quote extends React.Component<QuoteProps, QuoteState> {
  constructor(props: QuoteProps) {
    super(props);
  }

  render() {
    return (
      <div className='quote-container'>
        <h3 className='h3 quote-text'>
          {this.props.text}
        </h3>
        <p className='quote-sub-text'>
          {this.props.subText}
        </p>
      </div>
    );
  }
}
